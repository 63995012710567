	/*
  	Flaticon icon font: Flaticon
  	*/

@font-face {
    font-family: "Flaticon";
    src: url("../../assets/fonts/Flaticon.eot");
    src: url("../../assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/Flaticon.woff2") format("woff2"),
    url("../../assets/fonts/Flaticon.woff") format("woff"),
    url("../../assets/fonts/Flaticon.ttf") format("truetype"),
    url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: "Flaticon";
        src: url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
    }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
    font-family: Flaticon;
    font-size: 15px;
    font-style: normal;
}

.flaticon-coding:before { content: "\f100"; }
.flaticon-sugar-blood-level:before { content: "\f101"; }
.flaticon-computer:before { content: "\f102"; }
.flaticon-microscope:before { content: "\f103"; }
.flaticon-green-earth:before { content: "\f104"; }
.flaticon-ai:before { content: "\f105"; }
.flaticon-arrow-pointing-to-right:before { content: "\f106"; }
.flaticon-play-arrow:before { content: "\f107"; }
.flaticon-check-mark:before { content: "\f108"; }
.flaticon-laboratory:before { content: "\f109"; }
.flaticon-laboratory-1:before { content: "\f10a"; }
.flaticon-shopping-bag:before { content: "\f10b"; }
.flaticon-blood-pressure:before { content: "\f10c"; }
.flaticon-medical-history:before { content: "\f10d"; }
.flaticon-health-check:before { content: "\f10e"; }
.flaticon-health:before { content: "\f10f"; }
.flaticon-add:before { content: "\f110"; }
.flaticon-left-quotes-sign:before { content: "\f111"; }
.flaticon-right-quotes-symbol:before { content: "\f112"; }
.flaticon-arrow-pointing-to-left:before { content: "\f113"; }
.flaticon-facebook-placeholder-for-locate-places-on-maps:before { content: "\f114"; }
.flaticon-phone:before { content: "\f115"; }
.flaticon-smartphone-call:before { content: "\f116"; }
.flaticon-close-envelope:before { content: "\f117"; }
.flaticon-internet:before { content: "\f118"; }
.flaticon-paper-plane:before { content: "\f119"; }
.flaticon-check-mark-1:before { content: "\f11a"; }
.flaticon-left-arrow:before { content: "\f11b"; }
.flaticon-right-arrow:before { content: "\f11c"; }
.flaticon-pdf:before { content: "\f11d"; }
.flaticon-txt:before { content: "\f11e"; }